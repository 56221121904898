import React from "react";
import Scrollspy from "react-scrollspy";
import {Redirect} from "react-router";
import {Container} from "./components";
import {makeIdFromHeading} from "./container";

export class MyTabs extends React.Component {
    constructor(props) {
        super(props);

        const hashLoc = window.location.href.split("#")[1];
        const pref = this.props.preferredTabKey;
        let curr;
        this.tabs = (this.props.tabs || this.props.dynamicTabs()).filter(t => t != null);
        if (!!pref) {
            curr = this.tabs.reduce((tmp, curr, i) => curr.key === pref ? i : tmp, this.props.noDefaultTab ? null : 0);
        } else {
            curr = this.tabs.reduce((tmp, curr, i) => curr.name === hashLoc ? i : tmp, this.props.noDefaultTab ? null : 0);
        }
        this.state = {
            viewingTab: curr
        };
    }

    render() {
        this.tabs = (this.props.tabs || this.props.dynamicTabs()).filter(t => t != null);
        let tabCurr = this.tabs[this.state.viewingTab];
        return <>
            {(!this.props.hideHeaderIfOne || this.tabs.length > 1) && this.tabs.map((t, i) => {
                return <div key={"tabbtn" + i} className={"maxbtn"}
                            style={this.state.viewingTab !== i ? {backgroundColor: "#ddd", "color": "red!important"} : {}}
                            onClick={() => this.setState({viewingTab: i})}>
                    <span style={this.state.viewingTab !== i ? {"color": "#444"} : {}}>{t.name}</span>
                </div>
            })
            }
            {
                this.tabs.length > this.state.viewingTab && !!tabCurr &&
                <div key={"showing tab " + this.state.viewingTab}>{
                    tabCurr.content
                }
                    {!this.props.noRewrite && <Redirect to={"#" + tabCurr.name}/>}
                </div>
            }
            {
                this.props.noDefaultTab && !tabCurr && <p>
                    {this.props.noDefaultTabMessage}
                </p>
            }
        </>
    }
}

export class MyTabsExtended extends React.Component {
    constructor(props) {
        super(props);

        const hashLoc = window.location.href.split("#")[1];

        const tabs = this.props.tabs.filter(t => t != null);
        this.state = {
            viewingTab: tabs.reduce((tmp, curr, i) => curr.name === hashLoc ? i : tmp, 0),
            scrolledIntoView: false,
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            const [, hash] = window.location.href.split("#");
            const element = document.getElementById(hash);
            if (!hash) {
                this.setState({scrolledIntoView: true})
            }
            if (element) {
                element.scrollIntoView();
                this.setState({scrolledIntoView: true})
            } else {
                //console.log("no such element,")
            }
        }, 600);
    }

    render() {
        const tabs = this.props.tabs.filter(t => t != null);
        return <>
            <section className={"sectionHeader"}>
                {this.props.children}
                <Scrollspy
                    className={"sectionHeaderNav"}
                    items={tabs.filter(t => t !== null).map((t, i) => makeIdFromHeading(t.name))} currentClassName={"current"} offset={-300} onUpdate={(a) => {
                    if (this.state.scrolledIntoView) {
                        const pageurl = window.location.href.split("#")[0] + "#" + (!!a ? a.getAttribute("id") : "");
                        window.history.replaceState(null, document.title, pageurl);
                    }
                }}>
                    {
                        tabs.filter(t => t != null && !!t.name).map((t, i) => {
                            return <a key={"tabbtn" + i} href={"#" + makeIdFromHeading(t.name)} className={"maxbtn"}
                                      onClick={() => {
                                          this.setState({viewingTab: i})
                                      }}>
                                {t.name}
                            </a>
                        })

                    }
                </Scrollspy>
            </section>
            <section className={"sectionHeaderBackground"}/>

            <section className={"sectionContent"}>
                {
                    tabs.filter(t => t != null)
                        .map((t, i) => {
                            return <Container name={t.name} hideHeading={t.hideHeading} collapsable={t.collapsable} onCollapseChange={t.onCollapseChange}>
                                {t.content}
                            </Container>
                        })
                }
            </section>
        </>;
    }
}
