import React from "react";
import {FormContext} from "../form_context";
import {UserContext} from "../../user/UserContext";
import ReactSelect from "react-select";

// Parameter valueSelect: Die Werte dürfen einfach values von Select-Objekten sein. Also ["bank", "sepa"] als values werden als [{value: "bank", label: "Banküberweisung"}, {value "sepa", label: "SEPA-Lastschrift"}] dargestellt und aber wieder
// als einfaches Array gespeichert.
export class SelectfieldInput extends React.Component {
    static contextType = FormContext;
    state = {
        selectedOption: null,
    };


    localHandleChange = (selectedOption) => {
        if (this.props.readOnly) {
            alert("Dieses Feld kann leider nicht verändert werden.")
        }

        this.setState({selectedOption});
        const t = this.props.tag.split("_");
        const secondArgument = t.length === 2 ? t[1] : ""
        if (!!this.props.multiple) {
            this.context.updateState(t[0], secondArgument, this.props.valueSelect ? (selectedOption || []).map(x => x.value) || selectedOption : selectedOption)
        } else {
            this.context.updateState(t[0], secondArgument, selectedOption.value)
        }

        if (this.props.onChange !== undefined) {
            this.props.onChange(selectedOption)
        }
    };

    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.myRef = React.createRef();

    };

    componentDidMount() {
        //console.log("mount of " + this.props.tag, this.props)
    }

    componentDidUpdate() {
        const t = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (t.length === 2 && !this.props.multiple) {
            if ((state[t[0]] || {})[t[1]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]][t[1]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && this.context.updateState !== undefined) {
                    this.context.updateState(t[0], t[1], domValue)
                }
            }
        }
        if (t.length === 1) {
            if (state[t[0]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                if (stateValue !== domValue && this.props.updateState !== undefined) {
                    this.context.updateState(t[0], "", domValue)
                }
            }
        }

    }

    render() {
        if (!this.props.selectives) {
            return <p>Bitte Auswahloptionen angeben.</p>
        }

        const s = this.props.tag.split("_");
        let dv = "";
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }

        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        } else if (s !== undefined && s.length === 1 && state[s[0]]) {
            dv = state[s[0]]
        }
        let object_select = null;
        let defaultLabel;
        if (this.props.type !== "reactselect") {
            if (Array.isArray(this.props.selectives[0])) {
                object_select = this.props.selectives.filter(a => a !== null).map(a => <option key={a[0]}
                                                                                               value={a[0]}>{a[1]}</option>)
            } else {
                object_select = this.props.selectives.filter(a => a !== null).map(a => {
                    a = a.toString();
                    return <option key={a} value={a}>{a.split("|")[0]}</option>
                })
            }
        } else {
            if (!!this.props.multiple) {

                dv = null;
                let defaultLabel = null

            } else {
                let defaultLabelArray = this.props.selectives.filter(f => f.value === dv);
                if (defaultLabelArray.length > 0) {
                    defaultLabel = defaultLabelArray[0].label
                }
                if (dv !== undefined && dv !== null && dv.length === 0) {
                    dv = null;
                    defaultLabel = null
                }
            }

        }

        //const selectedOption = null//this.props.type !== "reactselect" ? null : state.remind.daysBeforeSelect;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
            }),

        };
        const t = this.props.tag.split("_");
        let value = null
        if (!dv && this.props.multiple) {
            const multiValue = t.length === 2 ? (this.context.state[t[0]] || {})[t[1]] || [] : (this.context.state[t[0]] || [])
            if (this.props.valueSelect) {
                value = multiValue.map(x => (this.props.selectives ? this.props.selectives.filter(y => y.value === x) || [] : [])[0] || x)
            } else {
                value = multiValue
            }

        } else {
            if (this.props.valueSelect) {
                value = {label: defaultLabel || dv?.label || dv, value: dv?.value || dv}
            } else {
                value = !!dv ? {label: defaultLabel || dv, value: dv} : null
            }
        }

        return <>
            {(this.props.selectives !== undefined && this.props.type === "reactselect") ?
                <div style={(!this.props.inline ? {} : {display: "inline-block"})}>
                    {
                        !this.props.noLabel && <label>
                        <span style={(!this.props.labelWidth ? {} : {width: this.props.labelWidth + "px"})}>
                            {this.props.name}
                        </span>
                        </label>
                    }
                    <div style={{width: this.props.width || "300px", display: "inline-block"}}>
                        <UserContext.Consumer>
                            {
                                ({club}) =>
                                    <ReactSelect
                                        id={this.props.id}
                                        key={this.props.tag}
                                        name={this.props.tag}
                                        style={{width: this.props.width}}
                                        placeholder={this.props.ph || "Auswählen"}
                                        styles={customStyles}
                                        value={value}
                                        //defaultValue={{label: defaultLabel || dv, value: dv}}
                                        isMulti={!!this.props.multiple}
                                        className={"reactSelectContainer " + (this.props.className || "")}
                                        onChange={this.localHandleChange}
                                        options={this.props.selectives}
                                        classNamePrefix={"MaxSelect"}
                                        noOptionsMessage={this.props.noOptionsMessage || (() => "nichts auszuwählen")}
                                        onMenuOpen={this.props.onMenuOpen}
                                        defaultMenuIsOpen={this.props.defaultOpen}
                                        menuPortalTarget={document.getElementById("react-select-portal")}
                                        components={this.props.components || {}}
                                        isDisabled={this.props.readOnly}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    //primary25: '#D7F367',
                                                    primary25: club.color,
                                                    primary: club.color,
                                                    neutral20: club.color,
                                                    neutral30: club.color,
                                                },
                                            }
                                        }}
                                    />
                            }
                        </UserContext.Consumer>
                    </div>
                </div> :

                <label style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
                    {
                        !this.props.noLabel &&
                        <span>
                        {this.props.name}
                     </span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives === undefined &&
                    <span>nichts auszuwählen</span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives !== undefined &&
                    this.props.noselectives
                    }
                    {/*this.props.selectives !== undefined && this.props.selectives.length > 0 && this.props.type === "select2" &&
                <Select2
                    data={this.props.selectives.map(a => {
                        return {id: a.id, "text": a.text.substr(0, 40)}
                    })}
                    value={dv}
                    onChange={(e) => {
                        if (this.props.tag.split("_").length == 2) {
                            this.props.updateState(this.props.tag.split("_")[0], this.props.tag.split("_")[1], e.target.value)
                        }
                    }
                    }
                    options={{placeholder: 'search for ' + this.props.name,}}
                />*/
                    }

                    {this.props.selectives !== undefined && (this.props.selectives.length > 0 || !Array.isArray(this.props.selectives)) && this.props.type !== "select2" && this.props.type !== "reactselect" &&
                    <select
                        key={this.props.tag}
                        name={this.props.tag}
                        style={{width: this.props.width}}
                        ref={this.myRef}
                        disabled={this.props.readOnly}
                        //multiple
                        onChange={() => {
                        }}
                        value={dv}
                        //className="js-example-basic-single"
                    >
                        {
                            this.props.demandSelect &&
                            <option value="">bitte auswählen</option>
                        }

                        {
                            object_select
                        }
                    </select>
                    }
                    &nbsp;{this.props.children}
                    {this.props.noBreaks === undefined && <br/>}
                </label>
            }
        </>
    }
}
