import React, {useContext, useState} from 'react'
import "../dashboard.sass"
import Status from "../core/status"
import "../course/courses.sass"
import {LightContainer} from "../core/components/components"
import {MyModal} from "../core/components/modal"
import {FaPlus} from "react-icons/fa"
import {AbrechnungsContext} from "./context"
import {UserContext} from "../user/UserContext"
import Consts from "../core/consts"
import {Link} from "react-router-dom"
import VouchersRedeem from "../voucher/voucher_redeem"
import {PaymentReference} from "./paymentRef"
import AsyncComponentWrapper from "../core/components/asyncComponentWrapper"
import {StatusVar} from "../club/club_inputs"

export default function ZahlungenSaldo({name}: {name?: string}) {
    const [open, setOpen] = useState(2)
    const [zahlungen, setZahlungen] = useState<any[]>([])
    const [{error, success}, setStatusVar] = useState<StatusVar>({})
    const abrechnung = useContext(AbrechnungsContext)
    const {user} = useContext(UserContext)

    return <LightContainer name={"Saldo"} className={"centercontent"}>
            <Status type="error" text={error}/>
            <Status type="success" text={success}/>
            Es sind <b>{Consts.moneyMax(Math.abs(abrechnung.eingaben) / 100)} {abrechnung.eingaben > 0 ? "Guthaben vorhanden" : "offen"}</b>.
            <UserContext.Consumer>
                {
                    userContext => !!userContext.user &&
                        <>
                            <ul style={{listStyle: "none"}}>
                                <li><FaPlus/> <Link to={{pathname: user?.role > 80 && abrechnung.userID ? "/bezahlmethode/add_for/" + abrechnung.userID : "/bezahlmethode/add", state: {name}}}>Zahlungsmethode hinzufügen</Link></li>
                                {
                                    (userContext.club?.paymentMethods || []).includes("sofort") &&
                                    <li><FaPlus/> <Link to={{pathname: user?.role > 80 && abrechnung.userID ? "/bezahlmethode/add/sofort/" + abrechnung.userID : "/bezahlmethode/add/sofort", state: {name}}}>Sofortüberweisung tätigen</Link></li>
                                }
                                {
                                    (userContext.club?.paymentMethods || []).includes("voucher") && <li><FaPlus/> <MyModal trigger={<em className={"leftmost normal"}>Gutschein einlösen</em>}><VouchersRedeem/></MyModal></li>
                                }
                            </ul>
                            {
                                abrechnung.eingaben < 0 && !abrechnung.userID &&
                                <AsyncComponentWrapper myImport={() => import("./zahlungen_qrcode")} reference={PaymentReference(userContext.user.ID)} club={userContext.club} amount={-abrechnung.eingaben}/>
                            }

                        </>

                }
            </UserContext.Consumer>
        </LightContainer>
}
