import React, {createRef} from 'react';
import "../dashboard.sass"
import {Container, Loader, MaxBtn} from "../core/components/components";
import {TextfieldInput} from "../core/input/basic";
import "./login.sass"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api";
import {FormContextWrapper} from "../core/form_context";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import "../general.sass"
import {browserName, isAndroid} from "react-device-detect";
import DOMPurify from "dompurify";
import {UserContext} from "./UserContext";
import {encodeGetParams} from "../core/helpers";
import {placeholderClub} from "../core/custom";

class Login extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            action: "login",
            keepLoggedIn: localStorage.getItem("keepLoggedIn") ?? false
        };
        this.setState = this.setState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.usernameField = createRef();
        const pn = this.props.location.pathname || "";
        if (pn.substr(0, 16) === "/benutzer/logout") {
            this.apiGet("/user/logout", resp => {
                if (!!props.addUserFromStatus) {
                    props.addUserFromStatus({user: undefined})
                }
                localStorage.removeItem("status_cache")
                //window.location.href = "/"
                //window.location.reload()
            });
        } else {
            this.apiGet("/user/status", resp => {
                if (resp.status === "logged_in") {
                    if (props.redir === undefined) {
                        let t = localStorage.getItem("redir");
                        localStorage.setItem("redir", "");
                        if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/benutzer/login" || t.substr(0, 16) === "/benutzer/logout") {
                            t = "/";
                        }
                        console.log("redir to ", t)
                        window.location.href = t
                    } else {
                        console.log("redir to ", props.redir)
                        window.location.href = props.redir
                    }


                }
            });

        }


    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })

    }

    componentDidMount() {
        let usernameField = this.usernameField.current;
        if (usernameField !== null) {
            usernameField.focus()
        }

    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true})
        const {action} = this.state;
        if (action === "pwreset") {
            this.apiPost("/user/request_pwreset", this.state);
            return;
        }
        this.apiPost("/user/login", {...this.state, version: window.vatomatorVersion}, resp => {
            this.setState({
                error: "",
                status: resp.message,
            });
            // localStorage.removeItem("verein_cache")

            this.loadClubData(resp.verein_ID, () => {
                if (this.props.redir === undefined) {
                    let t = localStorage.getItem("redir")
                    if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/benutzer/login" || t.substr(0, 16) === "/benutzer/logout") {
                        t = "/";
                    }
                    localStorage.setItem("redir", "/");
                    window.setTimeout(() => {
                        console.log("redir to ", t)
                        window.location.href = t
                    }, 500)
                } else {
                    console.log("redir to ", this.props.redir)
                    window.location.href = this.props.redir
                }
            })
        });

    }

    loadClubData = (verein_ID, callback) => {
        const time = new Date().getTime()
        this.apiGet("/club?" + encodeGetParams({verein_ID}), r => {
            const newClub = r.club || {}
            newClub["timestamp"] = time
            localStorage.setItem("verein_cache", JSON.stringify(newClub))
            if(this.props.addUserFromStatus){
                this.props.addUserFromStatus({club: {...(newClub || placeholderClub)}})
            }
            callback()
        })
    }

    render() {
//        console.log(this.props)
        //<img src={Porträt} style={{float: "right"}} width={"300px"} className={"animgrey"}/>
        const {action, error, loading} = this.state;
        return (
            <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit} afterUpdate={(a, b, c) => a === "keepLoggedIn" && localStorage.setItem("keepLoggedIn", c)}>


                <Container name={"Login"} hideHeading visible>
                    {
                        (isAndroid && browserName !== "Chrome WebView") && <div onClick={(e) => {
                            e.preventDefault()
                            window.location.href = "https://play.google.com/store/apps/details?id=com.linkomat.vereinfacht"
                        }}>
                            <img src={"/static/img/vereinfacht-icon512.png"} width={"100"} height={"auto"} style={{borderRadius: "10px", cursor: "pointer"}}/>
                            <MaxBtn
                                style={{verticalAlign: "top", marginTop: "38px"}}
                                type={"button"}
                            >
                                Android App Herunterladen
                            </MaxBtn>

                        </div>
                    }
                    <br/>{this.context.club?.showLogo ? <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.context.club?.logo || "")}}/> : null}
                    {!this.context.club?.showLogo && <h1>Vereine Einfach Verwalten</h1>}
                    <h2>Login</h2>
                    <Status type="error" text={error}/>
                    {
                        action === "login" && <>
                            <TextField name={"username"} label={"E-Mail"} placeholder={"max.mustermann@beispiel.at"} ref={this.usernameField}/><br/>
                            <TextField name={"password"} type={"password"} label={"Passwort"}/>
                            <br/>
                            <label style={{marginTop: "10px", display: "inline-block"}}>
                                <input type={"checkbox"} name={"keepLoggedIn"} checked={this.state.keepLoggedIn}/> Angemeldet bleiben?
                            </label>
                            <br/>
                            <em onClick={() => this.setState({action: "pwreset"})}>Passwort vergessen?</em>
                            <MaxBtn>Log In</MaxBtn>
                            {
                                this.context.club?.domain !== "mein.vereinfacht.at" && !window.ReactNativeWebView && <p>Du hast noch keinen Account? Dann gehe zu <Link to={"/registrieren"}>Registrierung</Link>!</p>
                            }
                            {
                                window.ReactNativeWebView && <p>Du hast noch keine Account? Dann gehe bitte auf die Website deines Vereins um dich dort zu registrieren. Nach dem Einrichten deines Passwortes, kannst du hierher zurückkehren.</p>
                            }
                        </>
                    }
                    {
                        action === "pwreset" && <>
                            <Status type="success" text={this.state.status}/>
                            <TextfieldInput tag={"email"} name={"E-Mail"} ph={"max.mustermann@beispiel.at"}/><br/>
                            {/* <DateInput name={"Geburtsdatum"} tag={"birthdate"}/><br/>*/}
                            <MaxBtn>Passwort-Zurücksetz-Link anfordern</MaxBtn>
                        </>
                    }
                    {/*<TextfieldInput name={"E-Mail"} tag={"username"} style={{width: "260px"}} state={this.state}/><br/>
                    <TextfieldInput name={"Passwort"} tag={"password"} style={{width: "260px"}} type={"password"} state={this.state}/><br/>
                    */}
                    <br/>
                    <Loader loading={loading}/>
                </Container>
            </FormContextWrapper>
        )
    }
}

export default Login;
