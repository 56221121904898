import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react"
import {FormContextWrapper} from "../core/form_context"
import {Loader, MaxBtn, MiniBtn} from "../core/components/components"
import {FaMinus, FaPlus} from "react-icons/fa"
import {CheckboxInput, InputContainer, TextfieldInput} from "../core/input/basic"
import Status from "../core/status"
import {SelectfieldInput} from "../core/input/select"
import {useOuterArrayState, useOuterKeyValuePairState, useOuterState} from "../core/key_value_pair_states"
import {maxiGet} from "../core/maxios"
import {AdditionalField} from "../user/UserContext"
import {FaTrashAlt} from "react-icons/all";

interface EditorInputProps<T extends (Record<string, any> | any[] | any)> {
    tag: string
    name: string
    values: (T extends object ? T : Record<string, T>)
    setState: Function
    save: Function
}

export interface StatusVar {
    success?: any
    error?: any
    loading?: boolean
}

export function useStatusVar() {
    return useState<StatusVar>({})
}

export type RequireSetStatusVar = {
    setStatusVar: Dispatch<SetStateAction<StatusVar>>
}

export type WithSetStatusVar = Partial<RequireSetStatusVar>

function MemberStatesInput({tag, name, values, setState, save}: EditorInputProps<string>) {
    const {local: memberStates, setLocal: set, updateLocal: update, addElement, deleteElement} = useOuterKeyValuePairState<string>(values, tag, name, setState)
    const [{error, loading}, setStatusVar] = useState<StatusVar>({})

    const checkIfMemberHasRole = (roleKey: any) => {
        setStatusVar({loading: true})
        const memberState = memberStates[roleKey]
        maxiGet("/role/users/" + memberState?.key, {setStatusVar}).then((resp) => {
            const usersCount = resp.users?.length || 0
            if (usersCount === 0 || window.confirm(`Es gibt noch ${usersCount} Personen mit der Mitgliedsrolle ${memberState?.value} (Code ${memberState?.key}). Trotzdem löschen? (Danach bitte noch Änderungen speichern)`)) {
                deleteElement(roleKey)
            }
        })
    }

    return <>
        <div>
            <h1>Rollen verwalten</h1>
            <p><>Aktuell gibt es <b>{Object.keys(memberStates).length}</b> Einträge.</>
            </p>
            <p>Rolle mit <b>Code &gt; 400</b> sind Vorstandsrollen.</p>
            <p>Rolle mit <b>Code &gt; 200</b> sind vom Mitgliedsbeitrag befreit, wenn die Einstellung <b>"Mitgliedsbeitr. fällig für"</b> entsprechend gesetzt wurde.</p>
            <MaxBtn onClick={(e: MouseEvent) => {
                e.stopPropagation()
                e.preventDefault()
                addElement()
            }}><FaPlus/> Neuen Code hinzufügen</MaxBtn></div>
        <FormContextWrapper value={{state: memberStates, setState: update}}>
            <Status type={"error"} text={error}/>
            <Loader loading={loading || false}/>
            <br/>
            {
                Object.keys(memberStates).map((key) => <div key={key}>
                    <TextfieldInput tag={key + "_key"} name={"Code"} labeltextstyle={{width: 50}} style={{width: 40}}/>
                    <TextfieldInput tag={key + "_value"} name={"Beschreibung"} labeltextstyle={{width: 90}}/>
                    <MiniBtn onClick={() => checkIfMemberHasRole(key)}><FaTrashAlt/></MiniBtn><br/>
                </div>)
            }
        </FormContextWrapper>
    </>
}


const AdditionalFieldsInput = ({tag, name, values, setState, save}: EditorInputProps<Partial<AdditionalField>[]>) => {
    const {local: additionalFields, setLocal: set, updateLocal: update} = useOuterArrayState<Partial<AdditionalField>>(values.map(v => ({...v, touched: true})), tag, name, setState)
    const [error,] = useState(undefined)

    const typeSelectables = [["text", "Text"], ["select", "Auswahlkomponente"], ["file", "Datei"], ["textarea", "Textarea"], ["checkbox", "Ja / Nein-Auswahl"], ["date", "Datum"]]
    const editableSelectives = [
        ["read", "Immer angezeigt, nur von Admins veränderbar"],
        ["registerWrite", " Immer angezeigt, von Nicht-Admins nur bei Neuregistrierung veränderbar"],
        ["write", "Immer angezeigt, immer veränderbar"],
        ["nonRegisterWrite", "Nur in Personenprofil angzeigen und veränderbar"],
        ["internal", "Nur für Admins angezeigt, nur für Admins veränderbar"],
    ]

    const addElement = (e: ChangeEvent<HTMLButtonElement>) => {
        e.preventDefault()
        set((a) => ([...a, {type: "text", label: "", editable: "internal", "showIfNonExistent": 0,}]))
    }
    const deleteElement = (index: number) => {
        set((a: any) => {
            const newA = [...a]
            newA.splice(index, 1)
            // const {[index]: _, ...newA} = a
            return newA
        })
    }

    const updateTagWithLabel = () => {
        if (additionalFields.filter(a => !a.touched && (a.tag || "").replaceAll("_", "") !== (a.label || "").replaceAll("_", "")).length > 0) {
            set((additionalFields) => additionalFields.map((x, index) => {
                return {...x, tag: x.touched ? x.tag : x.label?.replaceAll("_", "")}
            }))
        }
    }
    useEffect(updateTagWithLabel, [additionalFields])

    // No Tag: <InputContainer><TextfieldInput tag={key + "_tag"} name={"Tag"}/></InputContainer>

    return <>
        <FormContextWrapper value={{state: additionalFields, setState: update}}>
            <Status type={"error"} text={error}/>
            <div><h1>Zusätzliche Felder</h1>
                <p>Aktuell gibt es <b>{additionalFields.length}</b> Einträge.</p>
                <MaxBtn onClick={addElement}><FaPlus/> Neues Feld hinzufügen</MaxBtn></div>
            {
                additionalFields.map((data: any, key: number) => <div style={{border: "solid 1px #888", padding: 10, margin: 0, marginTop: 10, marginBottom: 10}} key={key}>
                    <MaxBtn style={{float: "right"}} onClick={() => deleteElement(key)}><FaMinus/> Lösche Feld</MaxBtn>
                    <InputContainer><SelectfieldInput tag={key + "_type"} name={"Typ"} selectives={typeSelectables}/></InputContainer>
                    <InputContainer><TextfieldInput tag={key + "_label"} name={"Name"}/></InputContainer>
                    <InputContainer><TextfieldInput tag={key + "_info"} name={"Information"}/></InputContainer>
                    <InputContainer block={true}><CheckboxInput labelWidth={270} tag={key + "_required"} name={"Erforderlich"}/></InputContainer>
                    {/*<InputContainer block={true}><CheckboxInput labelWidth={270} tag={key + "_showIfNonExistent"} name={"Zeige nur an, wenn noch nicht ausgefüllt"}/></InputContainer>*/}
                    <InputContainer block={true}><CheckboxInput labelWidth={270} tag={key + "_showInAMS"} name={"Zeige in Mitgliedskartenscan-Kiosk"}/></InputContainer>
                    <div><SelectfieldInput tag={key + "_editable"} name={"Bearbeitungsmodus"} selectives={editableSelectives}/></div>
                </div>)
            }
        </FormContextWrapper>
    </>
}

const MembershipFeesInput = ({tag, name, values, setState}: EditorInputProps<any>) => {
    const {local: fees, updateLocal: update, addElement} = useOuterKeyValuePairState(values, tag, name, setState)
    const [administer, setAdminister] = useState(false)
    const [error,] = useState(undefined)

    return <>
        <FormContextWrapper value={{state: fees, setState: update}}>
            <Status type={"error"} text={error}/>
            <div><label><span>Mitgliedsbeiträge</span> <MiniBtn onClick={() => setAdminister(a => !a)}>{administer ? "Verstecken" : "Verwalten"}</MiniBtn> {administer ?
                <MiniBtn onClick={addElement}><FaPlus/> Neuen Beitrag hinzufügen</MiniBtn> : <>{Object.keys(fees).length} Einträge</>}</label></div>
            {administer && Object.keys(fees).map((key) => <span key={key}>
            <InputContainer><SelectfieldInput tag={key + "_key"} selectives={[["junior", "junior"], ["normal", "normal"], ["senior", "senior"], ["supporter", "supporter"]]} name={"Code"}/></InputContainer>
            <InputContainer><TextfieldInput tag={key + "_value"} name={"Betrag"}/></InputContainer>
        </span>)
            }
        </FormContextWrapper>
    </>
    // <MiniBtn onClick={() => deleteElement(key)}><FaMinus/> Lösche Mitgliedsbeitrag</MiniBtn>
}

const BankInput = ({tag, name, values, setState}: EditorInputProps<any>) => {
    const {local: bank, updateLocal: update} = useOuterState(values, tag, name, setState)
    const [administer, setAdminister] = useState(false)
    const [error,] = useState(undefined)

    return <>
        <FormContextWrapper value={{state: bank, setState: update}}>
            <Status type={"error"} text={error}/>
            <div><label><span>Bank</span> <MiniBtn onClick={() => setAdminister(a => !a)}>{administer ? "Verstecken" : "Verwalten"}</MiniBtn></label></div>
            {administer && <span>
            <InputContainer><TextfieldInput tag={"bankname"} name={"Bankname"}/></InputContainer>
            <InputContainer><TextfieldInput tag={"bic"} name={"BIC"}/></InputContainer>
        </span>
            }
        </FormContextWrapper>
    </>
}

export {MemberStatesInput, AdditionalFieldsInput}
