import React, {useContext, useMemo, useState} from 'react';
import {Container, Loader, MaxBtn, MaxLink, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {MyTabs} from "../core/components/tabs";
import {CheckboxInput} from "../core/input/basic";
import "./course_form.css"
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import {Link} from "react-router-dom";
import {UserContext} from "../user/UserContext";
import {
    dateFormatBirthday,
    dateFormatInline,
    dateFormatTimeShort,
    dateFormatUnix,
    dateFormatUnixSmall
} from "../core/dateFuncs";
import UserSearchSelect, {DetailUserSearchSelect} from "../user/user_search_select";
import {downloadAsExcel} from "../core/download";
import {FormContext, FormContextWrapper} from "../core/form_context";
import {FaInfoCircle, FaList, FaStickyNote, FaUserPlus} from "react-icons/fa";
import Consts from "../core/consts"
import {AdminEdit, PartsEdit} from "./course_parts_edit";
import {checkPermissions} from "../club/permissionsHelpers";
import {maxiGet, maxiPost} from "../core/maxios";
import {CourseInput} from "../core/input/courseInput";
import {DetailUserSearchSelectTS} from "../user/user_search_select_advanced";
import {CoursePaymentPlanSelect} from "./paymentPlan/CoursePaymentPlanSelect";
import {usePaymentPlan} from "../core/hooks/usePaymentPlan";

const roles = ["Teilnehmer", "Trainer", "Warteliste"]

class CourseParts extends React.Component {
    static contextType = UserContext

    state = {
        error: "",
        events: [],
        parts: [],
        loading: true,
        amountsOutstandingLoaded: false,
        amountsOutstanding: {},
        adminViewMode: (localStorage.getItem('adminViewMode') === "0" ? 0 : 1)
    };

    constructor(props) {
        super(props);
        this.id = props.id;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this)
    }

    componentDidMount() {
        this.loadParts()
    }

    loadParts = () => {
        this.apiGet("/course/parts/" + this.id, resp => {
            this.setState(resp, this.loadAmountsOutstanding)
        });
    };
    loadAmountsOutstanding = () => {
        if (!checkPermissions(this.context, "course/saldo_read", this.id)) {
            return
        }
        this.apiGet("/courses/list/offen/for_course/" + this.id, resp => {
            this.setState({
                amountsOutstandingLoaded: true,
                amountsOutstanding: resp
            })
        })
    }

    render() {
        const context = this.context;
        const isAdmin = checkPermissions(context, "course/participants_read", this.id)
        const showAdminColumns = this.state.adminViewMode === 1 && isAdmin
        const isUnlimitedTrainerOrAdmin = (context?.user?.verein_ID !== Consts.CLUB_BEWEGUNGSWELT || isAdmin)

        const participantsCount = this.state.parts.reduce((c, obj) => obj.registration.role === 0 && obj.registration.timeLeave === 0 ? c + 1 : c, 0);
        const queueCount = this.state.parts.reduce((c, obj) => obj.registration.role === 2 && obj.registration.timeLeave === 0 ? c + 1 : c, 0);
        const tableButtonsLeft = (context) => <>
            {
                [Consts.CLUB_GUN_CLUB_LUSTENAU].includes(context?.user?.verein_ID) && <MaxLink className={"mini"} to={{
                    pathname: "/newsletter/vorlagen", state: {
                        emailAddresses: this.state.parts.map(t => ([t.child.email, t.child.ID, t.child.fullname])),
                    }
                }}>
                    <FaStickyNote/> Nachricht senden
                </MaxLink>
            }
            {
                isUnlimitedTrainerOrAdmin && <MiniBtn onClick={() => {
                    this.apiGet("/course/user_download_excel/" + this.id + "?showOutstanding=" + this.state.amountsOutstandingLoaded, ({content}) => {
                        downloadAsExcel(content, "Teilnehmer.xlsx")
                    })
                }
                }>
                    <FaList/> Liste Downloaden
                </MiniBtn>
            }
        </>
        const tableButtonsRight = (context) => <>{checkPermissions(context, "course/participants", this.id) &&
            <AddUserModal course={this.props.course} courseID={this.id} usersToHide={this.state.parts}
                          onChange={this.loadParts} btnClassname={"mini"} icon={<FaUserPlus/>}/>}</>


        return <div style={{marginTop: "-40px"}}>
            {
                this.state.error !== undefined && <><br/><br/><br/></>
            }
            <Status type="error" text={this.state.error}/>
            {participantsCount} von maximal &nbsp;
            <FormContext.Consumer>
                {({state}) => <>
                    {state.course !== undefined && state.course.maxparts + " Teilnehmern" + (state.course.minparts > 0 ? `, mindestens ${state.course.minparts} Teilnehmer` : "")}
                    &nbsp;
                    {queueCount > 0 && ", " + queueCount + " auf Warteliste"}

                    {
                        checkPermissions(context, "course/participants", this.id) &&
                        <FormContextWrapper value={{state: this.state, setState: this.setState}}
                                            afterUpdate={(a, b, val) => localStorage.setItem('adminViewMode', val)}
                                            style={{display: "inline", marginRight: 20, float: "left"}}>
                            <CheckboxInput labelWidth={200} name={"Trainer- / Adminansicht"}
                                           tag={"adminViewMode"} resetOnUnmount={false}/>
                        </FormContextWrapper>
                    }
                    <Loader loading={this.state.loading}/>
                    <MyReactTable
                        exportData={true}
                        noCsv={true}
                        tableKey={"Kursteilnehmer" + (this.state.adminViewMode === 1 ? " - Admin" : "")}
                        title={"Teilnehmer an Kurs " + (this.props.name || this.props.id) + (this.state.adminViewMode === 1 ? " - Admin" : "")}
                        configTitle={"Kursteilnehmer" + (this.state.adminViewMode === 1 ? " - Admin" : "")}
                        data={this.state.parts
                            .sort((a, b) => {
                                if (a.registration.timeLeave !== 0) {
                                    return 1
                                }
                                if (b.registration.timeLeave !== 0) {
                                    return -1
                                }
                                if (a.registration.role === b.registration.role) {
                                    if (a.registration.role === 2) {
                                        return (a.registration.timeCreation) - (b.registration.timeCreation)
                                    }
                                    return a.child.lastname < b.child.lastname ? -1 : 1
                                }
                                return -(a.registration.role || 3) + (b.registration.role || 3)
                            })
                        }
                        additionalButtonsRight={tableButtonsLeft(context)}
                        additionalButtonsLeft={tableButtonsRight(context)}
                        style={{width: "100%"}}
                        loading={this.state.loading}
                        columns={
                            [
                                {
                                    Header: "Name",
                                    id: "name",
                                    accessor: "child.fullname",
                                    Cell: (row) => <>
                                        {
                                            showAdminColumns ?
                                                <Link
                                                    to={"/benutzer/profil/" + row.original.child.ID}>{row.original.child.lastname}, {row.original.child.prename}</Link> :
                                                row.original.child.lastname + ", " + row.original.child.prename
                                        } &nbsp;
                                        {
                                            (!!row.original.registration.note || !!row.original.child.note) &&
                                            <MyModal trigger={<FaInfoCircle/>}>
                                                <Container name={"Bemerkung zu dieser Angebotsbuchung"}>
                                                    <b>{row.original.child.fullname}</b> hat bemerkt:<br/>
                                                    {row.original.registration.note}<br/>
                                                    {row.original.child.note}<br/>
                                                </Container>
                                            </MyModal>
                                        }</>,
                                    filterable: this.state.parts.length > 25,
                                    pdfCell: (row) => row.original.child.lastname + ", " + row.original.child.prename,
                                    pdfMinWidth: 160,
                                },
                                {
                                    Header: "Art",
                                    accessor: "registration.role",
                                    defaultHidden: !showAdminColumns,
                                    id: "kind",
                                    Cell: ({original, value}) => {
                                        const text = (original.registration.timeLeave > 0) ?
                                            ("ausg. " + dateFormatUnixSmall(original.registration.timeLeave) + " ") :
                                            roles[value]
                                        return <PartsEdit {...{
                                            original, value, course_ID: this.id, reload: () => {
                                                this.loadParts()
                                                this.props.reload && this.props.reload()
                                            }, course: state.course
                                        }}>{text} &nbsp;</PartsEdit>
                                    },
                                    pdfCell: ({original}) => {
                                        return (original.registration.timeLeave > 0) ?
                                            ("ausg. " + dateFormatUnixSmall(original.registration.timeLeave) + " ") :
                                            roles[original.registration.role]
                                    },
                                    maxWidth: 170,
                                },
                                {
                                    Header: "Anmeldung",
                                    show: isAdmin,
                                    defaultHidden: !showAdminColumns,
                                    accessor: "registration.timeCreation",
                                    Cell: row => dateFormatTimeShort(row.value),

                                },
                                {
                                    Header: "Start",
                                    show: isAdmin,
                                    defaultHidden: !showAdminColumns,
                                    accessor: "registration.timeStart",
                                    Cell: row => row.value > 0 ? dateFormatTimeShort(row.value) : "",
                                },
                                {
                                    Header: "Adresse",
                                    defaultHidden: true,
                                    id: "address",
                                    show: isUnlimitedTrainerOrAdmin,
                                    accessor: (row) => {
                                        return `${row.child?.route} ${row.child?.street_number}${row.child?.street_number_add ? " " + row.child?.street_number_add : ""}, ${row.child?.postal_code} ${row.child?.locality}`
                                    },
                                },
                                {
                                    Header: "E-Mail",
                                    defaultHidden: true,
                                    show: isUnlimitedTrainerOrAdmin,
                                    accessor: "child.email",
                                },
                                {
                                    Header: "Offenstand",
                                    defaultHidden: true,
                                    id: "offen",
                                    show: checkPermissions(this.context, "course/saldo_read", this.id),
                                    accessor: "child.verwalter_ID",
                                    sortMethod: (a, b) => {
                                        return (this.state.amountsOutstanding[a] || 0) - (this.state.amountsOutstanding[b] || 0);
                                    },
                                    pdfCell: (row) => {
                                        const outstanding = this.state.amountsOutstanding[row.original?.child?.verwalter_ID] || 0;
                                        if (outstanding >= 0) {
                                            return ""
                                        }
                                        return -outstanding / 100;
                                    },
                                    Cell: (row) => {
                                        const outstanding = this.state.amountsOutstanding[row.original?.child?.verwalter_ID] || 0;
                                        if (outstanding >= 0) {
                                            return ""
                                        }
                                        return Consts.moneyMax(-outstanding / 100);
                                    },
                                },
                                {
                                    Header: "Bemerkung",
                                    accessor: "registration.noteAdmin",
                                    id: "note",
                                    defaultHidden: !this.state.parts.some(p => p.registration.noteAdmin !== "") || showAdminColumns,
                                    Cell: ({row, original}) => <NoteAdminWrapper
                                        registration={original.registration} reload={this.loadParts}/>,
                                    pdfCell: (row) => row.original?.registration?.noteAdmin || "",
                                },
                                {
                                    Header: "Preis",
                                    accessor: "registration.price",
                                    id: "price",
                                    defaultHidden: true,
                                    Cell: ({original}) => <PriceAdminWrapper
                                        registration={original.registration} reload={this.loadParts}/>,
                                    pdfCell: (row) => (row.original?.registration?.price / 100) || "",
                                },
                                {
                                    Header: "Status",
                                    accessor: "registration.role",
                                    defaultHidden: showAdminColumns,
                                    id: "status",
                                    Cell: row => {
                                        if (row.original.registration.timeLeave > 0) {
                                            return "ausgetreten "
                                        }
                                        return [window.innerWidth < 800 ? "TN" : "Teilnehmer", window.innerWidth < 800 ? "TR" : "Trainer", window.innerWidth < 800 ? "W" : "Warteliste"][row.value]
                                    },
                                    pdfCell: row => {
                                        if (row.original.registration.timeLeave > 0) {
                                            return "ausgetreten "
                                        }
                                        return ["Teilnehmer", "Trainer", "Warteliste"][row.original.registration.role] || ""
                                    },
                                    maxWidth: window.innerWidth < 800 ? 40 : 100,
                                },

                                {
                                    Header: checkPermissions("user") ? "Geburtsdatum" : "Geburtsjahr",
                                    accessor: "child.birthdate",
                                    defaultHidden: showAdminColumns,
                                    filterable: true,
                                    Cell: row => row.value > 10000 ? dateFormatInline(row.value) : row.value,
                                    pdfCell: row => row.value > 10000 ? dateFormatBirthday(row.value) : row.value,
                                    maxWidth: 160,
                                    minWidth: 110,
                                    pdfMinWidth: 60,
                                },
                                {
                                    Header: "Telefon-Nr.",
                                    defaultHidden: showAdminColumns,
                                    id: "phone",
                                    accessor: "child.phone",
                                    filterable: true,
                                    show: isUnlimitedTrainerOrAdmin,
                                    Cell: ({value}) => <PhoneLink nr={value}/>,
                                    pdfCell: (row) => "+" + Consts.phoneNormalise(row.original.child.phone || ""),
                                    maxWidth: 160,
                                    minWidth: 140,
                                    pdfMinWidth: 60,
                                },
                                {
                                    Header: "Notfall-Nr.",
                                    defaultHidden: showAdminColumns,
                                    accessor: "child.phoneAdd",
                                    id: "notfall",
                                    filterable: true,
                                    show: isUnlimitedTrainerOrAdmin && doesAnyExist(this.state.parts, "child", "phoneAdd"),
                                    Cell: row => <PhoneLink nr={row.value}/>,
                                    pdfCell: (row) => row.original.child.phoneAdd || "",
                                    maxWidth: 160,
                                    minWidth: 140,
                                    pdfMinWidth: 60,
                                },
                                {
                                    Header: "Erz.-Ber.",
                                    defaultHidden: showAdminColumns,
                                    id: "erzber",
                                    filterable: true,
                                    show: doesAnyExist(this.state.parts, "child", "erzberID"),
                                    pdfShow: doesAnyExist(this.state.parts, "child", "erzberID"),
                                    Cell: row => row.original.child.erzberID > 0 ? this.state.erzber[row.original.child.erzberID].fullname : ""
                                },
                                {
                                    Header: "Tel-Nr Erz.-Ber.",
                                    defaultHidden: showAdminColumns,
                                    id: "erzber_tel",
                                    filterable: true,
                                    show: doesAnyExist(this.state.parts, "child", "erzberID"),
                                    Cell: row => row.original.child.erzberID > 0 ? <PhoneLink
                                        nr={this.state.erzber[row.original.child.erzberID].phone}/> : "",
                                    pdfCell: row => row.original.child.erzberID > 0 ? "+" + this.state.erzber[row.original.child.erzberID].phone : "",
                                    maxWidth: 160,
                                    minWidth: 140,
                                },
                                {
                                    Header: "Mitglied seit",
                                    show: true,
                                    defaultHidden: true,
                                    accessor: "child.memberSince",
                                    Cell: ({value}) => dateFormatUnix(value || 0),
                                },
                                {
                                    Header: "Letzter Login",
                                    show: true,
                                    defaultHidden: true,
                                    accessor: "child.lastLogin",
                                    Cell: ({value}) => dateFormatUnix(value || 0),
                                },
                                ...((context?.club || {}).additionalFields || [])?.map(field => ({
                                    Header: field.label,
                                    show: isUnlimitedTrainerOrAdmin,
                                    defaultHidden: true,
                                    accessor: "child.data." + field.tag,
                                }))

                            ]
                        }
                        defaultSorted={[
                            /*{
                                id: "kind",
                                desc: true
                            },*/
                        ]}
                    />
                </>
                }
            </FormContext.Consumer>


        </div>

    }
}

function NoteAdminWrapper({registration, reload}) {
    const [editMode, setEditMode] = useState(false);
    const reloadLocal = (newValue) => {
        if (newValue) {
            reload()
        } else {
            setEditMode(false)
        }
    }

    if (editMode) {
        return <AdminEdit {...{registration, reload: reloadLocal, focus: true, field: "noteAdmin"}}/>
    } else {
        return <div onClick={() => setEditMode(true)} style={{minHeight: "20px"}}>{registration.noteAdmin}</div>
    }
}

function PriceAdminWrapper({registration, reload}) {
    const [editMode, setEditMode] = useState(false);
    const reloadLocal = (newValue) => {
        if (newValue) {
            reload()
        } else {
            setEditMode(false)
        }
    }

    if (editMode) {
        return <AdminEdit {...{registration, reload: reloadLocal, focus: true, field: "price"}}/>
    } else {
        return <div onClick={() => setEditMode(true)}
                    style={{minHeight: "20px"}}>{Consts.moneyMax(registration.price / 100)}</div>
    }
}

const PhoneLink = ({nr}) => nr > 0 ? <a href={"tel:00" + nr}>+{Consts.phoneNormalise(nr)}</a> : "";
const doesAnyExist = (data, parent, child) => {
    if (data.length === 0) {
        return false
    }
    return data.some(d => d[parent] !== undefined && !!d[parent][child])
};
const doesAnyExist3Level = (data, parent, child, childChild) => {
    if (data.length === 0) {
        return false
    }
    return data.some(d => d[parent] !== undefined && !!d[parent][child] && !!d[parent][child][childChild])
};

function UserSelectButton({paymentPlan, onChange, courseID, child_ID}) {
    const [paymentPlanGroup, setPaymentPlanGroup] = useState()

    const hasPaymentPlan = !!paymentPlan?.length

    const registerUser = () => {
        maxiPost("/course/register", {
            course_ID: courseID,
            child_ID,
            paymentPlan_ID: paymentPlanGroup?.ID || undefined
        }).then(resp => {
            onChange()
        })
    }

    if (!!paymentPlan?.length) {
        return <MyModal trigger={<em>anmelden</em>}>
            <Container name={"Zahlungsweise auswählen"}>
                <CoursePaymentPlanSelect onChange={planGroup => setPaymentPlanGroup(planGroup)}
                                         paymentGroups={paymentPlan || []}/>
                <MiniBtn
                    onClick={() => registerUser()}>{paymentPlanGroup ? `Zahlungsmethode ${paymentPlanGroup.name} auswählen` : "keine Zahlungsmethode auswählen"}</MiniBtn>
            </Container>
        </MyModal>
    }

    return <>
        <em onClick={registerUser}>anmelden</em>
    </>
}

const AddUserModal = (props) => {
    const {
        courseID, usersToHide = [], onChange = () => {
        }, btnClassname = "", icon = null,
    } = props
    const [state, setState] = useState({})
    const [courseUsers, setCourseUsers] = useState([])
    const {club} = useContext(UserContext)
    const [{error, success, loading}, setStatusVar] = useState({})

    const paymentPlan = usePaymentPlan(courseID)

    useMemo(() => {
        const userIdList = ((state.course?.id || []).join(","))
        if (userIdList === "") {
            return
        }
        maxiGet("/course/parts/" + userIdList + "?deleted=false", {setStatusVar}).then(resp => {
            setCourseUsers(resp.parts)
        })
    }, [state.course])


    const dynamicTabs = () => [{
        name: "Mitglied suchen und hinzufügen", content:
            <UserSearchSelect
                selectButton={(id) => <UserSelectButton child_ID={id} courseID={courseID} paymentPlan={paymentPlan}
                                                        onChange={onChange}/>}
                furtherFields={{
                    status: {
                        label: "Anmeldestatus",
                        Cell: row => club.memberStates[row.original.memberStatus]
                    }
                }}
                usersHide={usersToHide.filter(({registration}) => registration.timeLeave === 0).map(p => p.child.ID)}
            />
    }, {
        name: "Mitglieder aus bestehendem Kurs hinzufügen", content: <>
            <FormContextWrapper value={{state, setState}}>
                <CourseInput tag={"course_id"} name={"Kurs auswählen"} limitless={"limitless"} multiple={true}/><br/>
            </FormContextWrapper>
            {courseUsers?.length > 0 && <DetailUserSearchSelectTS
                selectButton={(id) => <UserSelectButton child_ID={id} courseID={courseID} paymentPlan={paymentPlan}
                                                        onChange={onChange}/>}
                usersHide={usersToHide.filter(({registration}) => registration.timeLeave === 0).map(p => p.child.ID)}
                includedUsers={courseUsers.map(x => x.child)}
                includeSearch={false}
                heading={"Füge Benutzer aus dem Kurs hinzu"}/>}
        </>
    }]

    return <MyModal trigger={<MaxBtn className={btnClassname}>{icon}Person hinzufügen</MaxBtn>} modal>
        <Status type={"error"} text={error}/>
        <span style={{position: "auto"}}><MyTabs dynamicTabs={dynamicTabs}/></span>
        <b>Achtung</b>Der Mitgliedsstatus kann sich durch das Hinzufügen verändern, wenn das Angebot nur für Mitglieder
        zu Verfügung steht oder es wird automatisch
        die Variante genommen, die den Mitgliedssstatus
        nicht verändert (Nichtmitglieder buchen das Angebot als Nichtmitglied).

    </MyModal>
}

export default CourseParts;
