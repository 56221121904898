import React, {useContext, useState} from 'react'
import "../dashboard.sass"
import Consts from "../core/consts"
import Status from "../core/status"
import {Container, LightContainer, Loader, MaxBtn} from "../core/components/components"
import {MyModal} from "../core/components/modal"
import {dateFormatTimeShort, dateFormatUnix, timeFormat} from "../core/dateFuncs"
import {FaFileDownload, FaInfoCircle} from "react-icons/fa"

import {Link} from "react-router-dom"
import {apiGet} from "../core/api"

import {AbrechnungsContext} from "./context"
import {UserContext} from "../user/UserContext"
import {PartsEdit} from "../course/course_parts_edit"
import {encodeGetParams} from "../core/helpers"
import {StatusVar} from "../club/club_inputs"
import {Child, Course, CourseParticipantPaymentEntry} from "../core/interfaces/core"
import {ParticipantPaymentListModal} from "../course/paymentPlan/course_parts_payment_list"
import {downloadAsPdf} from "../core/download";
import {maxiGet} from "../core/maxios";
import {Club} from '../core/custom'

const showLimit = 10

interface CourseVisitsProps {
    userID: number
}

interface LocalCoursesGroup {
    course: CourseWithCoursePartsMixture
    child: Child
    discount: { code: string }
}

export interface CourseWithCoursePartsMixture extends Course {
    timeStart: number
    payments?: CourseParticipantPaymentEntry[]
    timeLeave: number
    timeCreation: number
    cp_price: number
    course_ID: number
    currency: number
    tax_rate: number
    role: number
    roombooking_start: number
    roombooking_end: number
    cp_price_nominal: number
    refunded: boolean
    cp_ID: number
    confirmationNumber: number
}

interface CourseVisitsState extends StatusVar {
    courses: LocalCoursesGroup[]
    open: number
    ausgaben: number
    show: { allFromGroup: boolean, from: number }
    users: Child[]
    showAll: boolean
    showCancelled: boolean
    status?: any
}

export default class CourseVisits extends React.Component<CourseVisitsProps, CourseVisitsState, UserContext> {
    static contextType = AbrechnungsContext
    context!: React.ContextType<typeof AbrechnungsContext>;
    apiGet: any


    constructor(props: CourseVisitsProps) {
        super(props)
        this.state = {
            open: 2,
            loading: true,
            ausgaben: 0,
            show: {
                allFromGroup: true,
                from: props.userID,
            },
            users: [],
            courses: [],
            showAll: false,
            showCancelled: true,
        }
        this.apiGet = apiGet.bind(this)
    }

    componentDidMount = () => {
        this.load()
    }

    load = () => {
        this.apiGet("/courses/list/visiting/" + this.props.userID, (resp: { courses: LocalCoursesGroup[] }) => {
            const ausgaben = resp.courses.reduce((s, c) => {
                return s + (c.course.cp_price)
            }, 0)
            this.setState({
                courses: resp.courses, loading: false,
                //ausgaben: ausgaben,
            });
            (this.context as any).setState({ausgaben: ausgaben, alfjsd: "ljdlf"});
        })

        this.apiGet("/user/subusers?edit=" + this.props.userID, (resp: { users: Record<number, Child> }) => {
            this.setState({users: Object.keys(resp.users).map(k => resp.users[k as unknown as number])})
        })
    }
    handleChange = (e: any) => {
        const s = e.target.name.split("_")
        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value
        this.setState({
            [s[0]]: {
                ...this.state[s[0] as keyof CourseVisitsState],
                [s[1]]: val
            }
        } as unknown as CourseVisitsState)
    }

    render() {
        const coursesFiltered = this.state.courses
            .filter(c => (this.state.show.from === 0 || c.child.ID === this.state.show.from) && (this.state.showCancelled || c.course.timeLeave === 0))
        const t0 = (new Date()).getTime() / 1000

        const priceAlreadyToPay = (c: LocalCoursesGroup) => (c.course.payments || []).filter(payment => !payment.dueDate || new Date(payment.dueDate).getTime() < new Date().getTime())
            .reduce((sum, next) => sum + next.price, 0)
        const renderPartialPrice = (prices: number[], currency: number, taxRate: number, club: Club, timeCreation: number) => {
            const allGroupPricesAreSame = new Set(prices).size === 1

            if (allGroupPricesAreSame) {
                return `${prices.length}x ${Consts.moneyMax(prices[0] / 100, currency, taxRate, club, timeCreation)}`
            }
            return prices.map(price => Consts.moneyMax(price / 100, currency, taxRate, club, timeCreation)).join(", ")
        }
        const renderDiscount = (amount: number, numberOfPayments?: number) => {
            const numberOfPaymentsLocal = Math.max(numberOfPayments || 1, 1)

            return `${numberOfPaymentsLocal > 1 ? `${numberOfPaymentsLocal}x ` : ""} ${Consts.moneyMax(amount / 100 / numberOfPaymentsLocal)}`
        }

        return (
            <LightContainer name={"Kurs / Einheiten Buchungen"} visible className={"centercontent"}>
                <Status type="error" text={this.state.error}/>
                <Status type="success" text={this.state.status}/>


                <UserContext.Consumer>
                    {context => <>
                        {
                            context.user && (context.user.role > 50 || context.user.verwalter) ? <>
                                {/* <form onChange={this.handleChange}>
                            <CheckboxInput name={"von allen in Gruppe?"} tag={"show_allFromGroup"}/>
                        </form>*/}

                                {
                                    context.user.role > 50 &&
                                    <MaxBtn style={{float: "right"}}
                                            onClick={() => this.setState({showCancelled: !this.state.showCancelled})}>Abgebrochene {this.state.showCancelled ? "ausblenden" : "anzeigen"}</MaxBtn>
                                }
                                <br/>
                                {
                                    [{prename: "allen", ID: 0}, ...this.state.users].map(u => {
                                        return <MaxBtn
                                            key={"tabbtn" + u.ID}
                                            style={this.state.show.from !== u.ID ? {backgroundColor: "#ddd"} : {}}
                                            onClick={() => this.setState({show: {...this.state.show, from: u.ID}})}
                                        >
                                            {u.prename}
                                        </MaxBtn>
                                    })
                                }
                            </> : null
                        }

                        <ul>
                            <Loader loading={this.state.loading}/>
                            {
                                coursesFiltered
                                    .sort((a, b) => -a.course.timeCreation + b.course.timeCreation)
                                    .slice(0, this.state.showAll ? 1000000 : showLimit)
                                    .map(c => {
                                        //console.log(c,c.timeCreation)
                                        const courseIsMSFee = c.course.tags === "1"
                                        return <li key={c.course.cp_ID} style={{cursor: "loading"}}>
                                            {/*{this.state.open === key && <FaAngleDown className={"icon"}/>}
                            {this.state.open !== key && <FaAngleRight className={"icon"}/>}*/}
                                            {
                                                c.course.course_ID === 0 ? <>
                                                        <Link to={"/benutzer/profil/" + c.child.ID}><b>{c.child.prename}</b></Link> hat
                                                        den <b>Mitgliedsbeitrag</b> für das Jahr startend
                                                        am {dateFormatUnix(c.course.timeCreation)} iHv. {Consts.moneyMax(c.course.cp_price / 100, c.course.currency, c.course.tax_rate, context?.club, c.course.timeCreation)} zu
                                                        bezahlen
                                                    </> :
                                                    <>
                                                        {
                                                            this.state.show.from === 0 && <><Link
                                                                to={"/benutzer/profil/" + c.child.ID}><b>{c.child.prename}</b></Link>:&nbsp;</>
                                                        }
                                                        {
                                                            c.course.role === 0 &&
                                                            <>
                                                                {
                                                                    courseIsMSFee ?
                                                                        <>{context?.user?.role > 20 ? <Link
                                                                                to={"/kurse/" + c.course.ID}>{c.course.name}</Link> :
                                                                            <b style={{borderBottom: "1px solid var(--mainColor)"}}>{c.course.name}</b>} iHv.</> :
                                                                        <>
                                                                            {c.course.course_ID === -4 ? "kaufte einen Gutschein für " : ""/*(c.course.events_end !== null && c.course.events_end > t0 ? "besucht" : "besuchte")*/}
                                                                            <b>
                                                                                {c.course.name === null ? c.course.note :
                                                                                    (c.course.isRoom === 1 ? <Link
                                                                                            to={"/raum/buchungen?" + encodeGetParams({
                                                                                                course_id: c.course.ID,
                                                                                                view: "week"
                                                                                            })}>{c.course.name}</Link> :
                                                                                        <Link
                                                                                            to={"/kurse/" + c.course.ID}>{c.course.name}</Link>)
                                                                                }
                                                                                {
                                                                                    c.course.roombooking_start && <>, {dateFormatTimeShort(c.course.roombooking_start)}-{timeFormat(c.course.roombooking_end)}</>
                                                                                }
                                                                            </b>
                                                                        </>
                                                                }
                                                                <>&nbsp;
                                                                    {c.course.payments?.length ?
                                                                        renderPartialPrice(c.course.payments.map(payment => payment.price), c.course.currency, c.course.tax_rate, context?.club, c.course.timeCreation) :
                                                                        Consts.moneyMax(c.course.cp_price / 100, c.course.currency, c.course.tax_rate, context?.club, c.course.timeCreation)}&nbsp;
                                                                    {
                                                                        !!c.course.payments?.length && priceAlreadyToPay(c) !== c.course.cp_price && <>
                                                                            ({Consts.moneyMax(priceAlreadyToPay(c) / 100, c.course.currency, c.course.tax_rate, context?.club, c.course.timeCreation)} bereits
                                                                            fällig)&nbsp;
                                                                        </>
                                                                    }
                                                                    {(!!c.course.discount_ID || (c.course.cp_price_nominal > 0 && c.course.cp_price !== c.course.cp_price_nominal)) && (c.course.cp_price_nominal - c.course.cp_price) / 100 > 0 && "(erhaltener Rabatt: " + renderDiscount(c.course.cp_price_nominal - c.course.cp_price, c.course.payments?.length) + (c.discount?.code && c.discount?.code !== "RABATT" ? (" - Code: " + (c.discount?.code || "unbekannt") + ") ") : ") ")}
                                                                    {/*(da {Club.memberStatesShort[c.child.memberStatus]})&nbsp;*/}
                                                                    {/*c.duedate > 0 && " am " + dateFormatUnix(c.duedate)*/}
                                                                    {1000 * c.course.paymentDueDate >= new Date().getTime() && !c.course.payments?.length && <> (fällig
                                                                        am {dateFormatUnix(c.course.paymentDueDate)}) </>}
                                                                    {
                                                                        c.course.coursePaymentModel_ID !== null && <>&nbsp;
                                                                            <ParticipantPaymentListModal
                                                                                participantPayments={c.course.payments}
                                                                                course={c.course} onChange={() => {
                                                                                this.load()
                                                                                this.context.state.reloadPayments()
                                                                            }}/>
                                                                            &nbsp;
                                                                        </>
                                                                    }
                                                                </>
                                                            </>
                                                        }
                                                        {
                                                            (c.course.role === 1 || c.course.role === 2) &&
                                                            <>{/*c.course.events_end !== null && c.course.events_end > t0 ? "ist" : "war"*/}
                                                                <b>{c.course.name === null ? c.course.note :
                                                                    <Link
                                                                        to={"/kurse/" + c.course.ID}>{c.course.name}</Link>
                                                                }</b>&nbsp;
                                                                ({c.course.role === 1 ? "Trainer" + (c.child.sex === "male" ? "" : "in") : "Warteliste"})&nbsp;
                                                            </>
                                                        }

                                                        ({courseIsMSFee ? "vorgeschrieben" : (c.course.role === 1 ? "gebucht" : "Start")} am {dateFormatUnix(c.course.timeStart || c.course.timeCreation)}
                                                        {
                                                            c.course.timeLeave > 0 &&
                                                            <>, <b>abgebrochen</b> am {dateFormatUnix(c.course.timeLeave)}{c.course.cp_price > 0 ? (c.course.refunded ? ", Kosten gutgeschrieben" : ", Kosten nicht gutgeschrieben") : ""}</>
                                                        }
                                                        )
                                                        {
                                                            !!c.course.note && <>&nbsp;
                                                                <MyModal trigger={<FaInfoCircle/>}>
                                                                    <Container name={"Bemerkung zu dieser Angebotsbuchung"}>
                                                                        {c.course.note}
                                                                    </Container>
                                                                </MyModal>
                                                            </>
                                                        }

                                                        <PartsEdit {...{
                                                            course_ID: c.course.ID,
                                                            course: c.course,
                                                            reload: () => {
                                                                this.load()
                                                                this.context.state.reloadPayments()
                                                            },
                                                            original: {
                                                                registration: {
                                                                    ...c.course,
                                                                    ID: c.course.cp_ID,
                                                                    price: c.course.cp_price,
                                                                    price_nominal: c.course.price
                                                                }, child: c.child
                                                            }
                                                        }}/>

                                                        <InvoiceDownloader c={c}/>
                                                    </>
                                            }.

                                        </li>
                                    })

                            }
                            {!this.state.showAll && showLimit <= coursesFiltered.length &&
                                <em style={{marginLeft: 0}} onClick={() => this.setState({showAll: true})}>alle
                                    anzeigen</em>}

                            {
                                coursesFiltered.length === 0 && !this.state.loading && "Es wurde noch keine Einheit / kein Kurs gebucht."
                            }
                        </ul>

                    </>
                    }
                </UserContext.Consumer>


            </LightContainer>
        )
    }
}

interface InvoiceDownloaderProps {
    c: LocalCoursesGroup
}

function InvoiceDownloader({c}: InvoiceDownloaderProps) {
    const context = useContext(UserContext)
    const [{error, loading}, setStatusVar] = useState<StatusVar>({});
    if (c.course.cp_price <= 0 || !context.club.sendInvoicePDFs || !c.course.confirmationNumber) {
        return null;
    }
    return <>
        <Status type={"error"} text={error}/>

        <em onClick={() => maxiGet(`/invoice/course_part/${c.course.cp_ID}?kind=normal`, {setStatusVar}).then(response => downloadAsPdf(response.content, `Rechnung ID-${c.course.cp_ID}.pdf`))}>
            <FaFileDownload/> Rechnung herunterladen
        </em>

        {
            c.course.timeLeave > 0 &&
            <em onClick={() => maxiGet(`/invoice/course_part/${c.course.cp_ID}?kind=canc`, {setStatusVar}).then(response => downloadAsPdf(response.content, `Rechnung ID-${c.course.cp_ID}.pdf`))}>
                <FaFileDownload/> Storno-Rechnung herunterladen
            </em>
        }
    </>
}

/*<ul>{this.state.open === key &&
                                //Object.keys(child)
                                Object
                                    .keys(c)
                                    .filter(k => skip.indexOf(k) < 0)
                                    .map(k => {
                                        if (k === "startdate" || k === "enddate") {
                                            return null;
                                        }

                                        if (k === "enrolment_mod_time") {
                                            return <span>
                                    <li>Kursdauer: von {dateFormatUnix(c["startdate"])} bis {dateFormatUnix(c["enddate"])}</li>
                                    <li>
                                   Datum der Registrierung: {dateFormatUnix(c[k])}
                                </li></span>
                                        }
                                        if (k.substr(k.length - 4) === "than") {
                                            return <li>
                                                {Consts.translate(k)}: {c[k]} Jahre
                                            </li>
                                        }
                                        if (k.substr(0, 5) === "price") {
                                            return <li>
                                                {Consts.translate(k)}: {Consts.moneyMax(c[k])}
                                            </li>
                                        }
                                        if (typeof c[k] === "boolean") {
                                            return <li>
                                                {Consts.translate(k)}: {c[k] ? "ja" : "nein"}
                                            </li>
                                        }

                                        return <li>
                                            {Consts.translate(k)}: {c[k]}
                                        </li>
                                    })
                                }</ul>*/
