import React, {useContext, useMemo, useState} from "react"
import {FormContext} from "../form_context"
import {UserContext} from "../../user/UserContext"
// @ts-ignore
import ReactSelect from "react-select"
import {useFormContext, useWatch} from "react-hook-form"

type LabelValuePair<L = any, V = any> = { label: L, value: V }

export interface SelectfieldInputProps {
    tag: string
    selectives: LabelValuePair[]
    readOnly?: boolean
    multiple?: boolean
    onChange?: (values: LabelValuePair[]) => any
    updateState?: Function
    width?: any
    ph?: string
    noBreak?: boolean
    name?: string | JSX.Element | null
    noLabel?: boolean
    inline?: boolean
    labelWidth?: number
    noOptionsMessage?: Function
    defaultOpen?: boolean
    components?: JSX.Element
    onMenuOpen?: Function
    demandSelect?: boolean
    noBreaks?: boolean
    RightComponent?: (props: { selected: any }) => JSX.Element | null
}

export function getAccessors(tag: string) {
    const accessors = tag.split("_")
    if (accessors.length === 1) {
        return accessors
    }
    const secondArgument = accessors.length >= 2 ? tag.substring(tag.search("_") + 1) : ""
    return [accessors[0], secondArgument]
}

const SelectFieldInput2 = ({tag, selectives, RightComponent, ...props}: SelectfieldInputProps) => {
    const {state, updateState, setState} = useContext(FormContext)
    const accessors = tag.split("_")
    const secondArgument = accessors.length >= 2 ? tag.substring(tag.search("_") + 1) : ""
    const value = accessors.length >= 2 ? (state[accessors[0]] || {})[secondArgument] : state[accessors[0]]
    const [currentSavedValue, setCurrentSavedValue] = useState(value)

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
        }),

    }

    useMemo(() => {
        if (value !== currentSavedValue) {
            setCurrentSavedValue(value)
        }
    }, [value])

    const localHandleChange = (selectedOption: LabelValuePair | LabelValuePair[]) => {
        if (props.readOnly) {
            alert("Dieses Feld kann leider nicht verändert werden.")
            return
        }

        const localValue = Array.isArray(selectedOption) ? (selectedOption || []).map(x => x.value) : selectedOption.value || null
        if (updateState) {
            updateState(accessors[0], secondArgument, localValue)
        } else {
            setState && setState((state: any) => ({...state, [accessors[0]]: {...state[accessors[0]], [secondArgument]: localValue}}))
        }
        props.onChange && props.onChange(Array.isArray(selectedOption) ? selectedOption : [selectedOption])
    }

    return <div style={(!props.inline ? {} : {display: "inline-block"})}>
        {
            !props.noLabel && <label>
                        <span style={(!props.labelWidth ? {} : {width: props.labelWidth + "px"})}>
                            {props.name}
                        </span>
                {RightComponent && <RightComponent key={currentSavedValue} selected={currentSavedValue}/>}
            </label>
        }
        <div style={{width: props.width || "300px", display: "inline-block"}}>
            <UserContext.Consumer>
                {
                    ({club}) =>
                        <>
                            <ReactSelect isMulti={props.multiple} options={selectives} onChange={localHandleChange} key={tag}
                                         value={selectives.filter(s => Array.isArray(value) ? value.includes(s.value) : (value === 0 ? ""+value === ""+s.value : value === s.value))}
                                         name={tag}
                                         style={{width: props.width}}
                                         placeholder={props.ph || "Auswählen"}
                                         styles={customStyles}
                                         classNamePrefix={"MaxSelect"}
                                         noOptionsMessage={props.noOptionsMessage || (() => "nichts auszuwählen")}
                                         onMenuOpen={props.onMenuOpen}
                                         defaultMenuIsOpen={props.defaultOpen}
                                         menuPortalTarget={document.getElementById("react-select-portal")}
                                         components={props.components || {}}
                                         isDisabled={props.readOnly}
                                         theme={(theme: any) => {
                                             return {
                                                 ...theme,
                                                 borderRadius: 0,
                                                 colors: {
                                                     ...theme.colors,
                                                     //primary25: '#D7F367',
                                                     primary25: club.color,
                                                     primary: club.color,
                                                     neutral20: club.color,
                                                     neutral30: club.color,
                                                 },
                                             }
                                         }}
                            />
                        </>
                }
            </UserContext.Consumer>
        </div>
    </div>
}

export const SelectFieldInputHook = ({tag, selectives, ...props}: SelectfieldInputProps) => {
    const {multiple} = props
    const form = useFormContext()
    const watch = useWatch(form)
    const value = watch[tag] as LabelValuePair[] | LabelValuePair

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
        }),
    }

    const localHandleChange = (selectedOption: LabelValuePair[]) => {
        if (props.readOnly) {
            alert("Dieses Feld kann leider nicht verändert werden.")
            return
        }

        const selected  = multiple ? selectedOption[0] : selectedOption
        form.setValue(tag, selected)
        props.onChange && props.onChange(selectedOption)
    }
    return <div style={(!props.inline ? {} : {display: "inline-block"})}>
        {
            !!props.name && <label>
                        <span style={(!props.labelWidth ? {} : {width: props.labelWidth + "px"})}>
                            {props.name}
                        </span>
            </label>
        }
        <div style={{width: props.width || "350px", display: "inline-block"}}>
            <UserContext.Consumer>
                {
                    ({club}) =>
                        <>
                            <ReactSelect isMulti={multiple} options={selectives} onChange={localHandleChange} key={tag}
                                         value={selectives.filter((s, index) => {
                                             if(!value && index === 0) {
                                                 return true
                                             }
                                             return Array.isArray(value) ? !!value.find(x => x.value === s.value) : value?.value === s.value
                                         })}
                                         name={tag}
                                         style={{width: props.width}}
                                         placeholder={props.ph || "Auswählen"}
                                         styles={customStyles}
                                         classNamePrefix={"MaxSelect"}
                                         noOptionsMessage={props.noOptionsMessage || (() => "nichts auszuwählen")}
                                         onMenuOpen={props.onMenuOpen}
                                         defaultMenuIsOpen={props.defaultOpen}
                                         menuPortalTarget={document.getElementById("react-select-portal")}
                                         components={props.components || {}}
                                         isDisabled={props.readOnly}
                                         theme={(theme: any) => {
                                             return {
                                                 ...theme,
                                                 borderRadius: 0,
                                                 colors: {
                                                     ...theme.colors,
                                                     //primary25: '#D7F367',
                                                     primary25: club.color,
                                                     primary: club.color,
                                                     neutral20: club.color,
                                                     neutral30: club.color,
                                                 },
                                             }
                                         }}
                            />
                        </>
                }
            </UserContext.Consumer>
        </div>
    </div>
}

export default SelectFieldInput2
